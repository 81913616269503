import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  usuario = '';
  correo = '';

  //constructor(private router: Router) {}
  //router: Router;
  public appPages0 = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Login', url: '/login', icon: 'mail' },
    { title: 'Registrarse', url: '/registro', icon: 'attach' },
    { title: 'Acerca de OCPD', url: '/valores', icon: 'book' },
    { title: 'Información Certificación', url: '/infocertificacion', icon: 'help-circle' },
    { title: 'Políticas de Certificación', url: '/politicas', icon: 'clipboard' },
    { title: 'Reglamento de Certificación', url: '/reglamento', icon: 'clipboard' },
    { title: 'Quejas y Apelaciones', url: '/quejas', icon: 'clipboard' },
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    // { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  public appPages = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    // { title: 'Solicitud', url: '/request', icon: 'mail' },
    { title: 'Usuarios', url: '/usuarios', icon: 'attach' },
    { title: 'Preguntas', url: '/preguntas', icon: 'attach' },
    { title: 'Examenes', url: '/examenes', icon: 'attach' },
    { title: 'Estado Examen', url: '/estadoexamen', icon: 'attach' },
    { title: 'Acerca de OCPD', url: '/valores', icon: 'book' },
    { title: 'Información Certificación', url: '/infocertificacion', icon: 'help-circle' },
    { title: 'Políticas de Certificación', url: '/politicas', icon: 'clipboard' },
    { title: 'Reglamento de Certificación', url: '/reglamento', icon: 'clipboard' },
    { title: 'Quejas y Apelaciones', url: '/quejas', icon: 'clipboard' },
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    // { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  public appPages2 = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Estado Examen', url: '/estadoexamen', icon: 'attach' },
    { title: 'Acerca de OCPD', url: '/valores', icon: 'book' },
    { title: 'Información Certificación', url: '/infocertificacion', icon: 'help-circle' },
    { title: 'Políticas de Certificación', url: '/politicas', icon: 'clipboard' },
    { title: 'Reglamento de Certificación', url: '/reglamento', icon: 'clipboard' },
    { title: 'Quejas y Apelaciones', url: '/quejas', icon: 'clipboard' },
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    // { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  public appPages3 = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Solicitud', url: '/solicitud', icon: 'mail' },
    { title: 'Mis Solicitudes', url: '/solicitudes', icon: 'paper-plane' },
    { title: 'Acerca de OCPD', url: '/valores', icon: 'book' },
    { title: 'Información Certificación', url: '/infocertificacion', icon: 'help-circle' },
    { title: 'Políticas de Certificación', url: '/politicas', icon: 'clipboard' },
    { title: 'Reglamento de Certificación', url: '/reglamento', icon: 'clipboard' },
    { title: 'Quejas y Apelaciones', url: '/quejas', icon: 'clipboard' },
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    // { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  public menu=0;


  //this.appPages.push({ title: 'Inbox', url: '/folder/Inbox', icon: 'mail' });
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {}

  ngOnInit() {
    const usuarioInfo = JSON.parse(localStorage.getItem('user')) ;
    console.log(usuarioInfo);
    this.usuario = usuarioInfo.nombres + ' ' + usuarioInfo.apellidos;
    this.correo = usuarioInfo.correo;
    if(usuarioInfo.tipoUsuario === 'admin') { this.menu = 1;};
    if(usuarioInfo.tipoUsuario === 'evaluador') { this.menu = 2;};
    if(usuarioInfo.tipoUsuario === 'candidato') { this.menu = 3;};
  }

  onLogout() {
    localStorage.clear();
    // window.location.reload();
    window.location.assign('/');
  }
}
