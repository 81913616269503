import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./maestros/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'preguntas',
    loadChildren: () => import('./maestros/preguntas/preguntas.module').then( m => m.PreguntasPageModule)
  },
  {
    path: 'examenes',
    loadChildren: () => import('./maestros/examenes/examenes.module').then( m => m.ExamenesPageModule)
  },
  {
    path: 'solicitud',
    loadChildren: () => import('./solicitud/solicitud.module').then( m => m.SolicitudPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'solicitudes',
    loadChildren: () => import('./solicitudes/solicitudes.module').then( m => m.SolicitudesPageModule)
  },
  {
    path: 'estadoexamen',
    loadChildren: () => import('./maestros/estadoexamen/estadoexamen.module').then( m => m.EstadoexamenPageModule)
  },
  {
    path: 'valores',
    loadChildren: () => import('./valores/valores.module').then( m => m.ValoresPageModule)
  },
  {
    path: 'infocertificacion',
    loadChildren: () => import('./infocertificacion/infocertificacion.module').then( m => m.InfocertificacionPageModule)
  },
  {
    path: 'politicas',
    loadChildren: () => import('./politicas/politicas.module').then( m => m.PoliticasPageModule)
  },
  {
    path: 'reglamento',
    loadChildren: () => import('./reglamento/reglamento.module').then( m => m.ReglamentoPageModule)
  },
  {
    path: 'quejas',
    loadChildren: () => import('./quejas/quejas.module').then( m => m.QuejasPageModule)
  },
  {
    path: 'validacodigo',
    loadChildren: () => import('./validacodigo/validacodigo.module').then( m => m.ValidacodigoPageModule)
  },
  {
    path: 'examen',
    loadChildren: () => import('./examen/examen.module').then( m => m.ExamenPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
